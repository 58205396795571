.leftbar {
  box-shadow: $box-shadow;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  padding: 10px;
  z-index: 99;
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary-100;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid $primary-100;
  }

  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile-img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      color: $secondary;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: $light;
    }

    .btn-primary {
      font-size: 12px;
    }
  }

  .profile-info {
    background-color: #ffeeec;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;

    .info-heading {
      color: #7a57ff;
      font-size: 14px;
    }

    .info-edit {
      color: $secondary;
      font-size: 12px;
    }

    h5 {
      color: $secondary;
      font-size: 14px;
    }

    p {
      color: $light;
      font-size: 14px;
    }

    .flag {
      height: 20px;
      width: auto;
      margin-left: 8px;
    }
  }

  .queries {
    // padding-left: 20px;
    flex: 1;
    h5 {
      color: $secondary;
      font-size: 14px;
      font-weight: 600;
    }

    p {
      color: $light;
      font-size: 14px;
      // margin-top: 5px;
    }

    .flag-img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      // margin-bottom: 4px;
    }

    .accurate {
      background-color: #92d4e2;
      color: #fff;
      padding: 2px 8px;
      font-size: 10px;
      margin-left: 5px;
    }
  }

  .chat-profile-image {
    background-color: $xlight;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;

    .info-heading {
      color: #7a57ff;
      font-size: 14px;
    }

    .kundali {
      background-color: $white;
      box-shadow: $box-shadow;
      height: 80px;
      width: 100%;
      padding: 5px;
      border-radius: 8px;
      margin-bottom: 10px;
      object-fit: cover;
    }

    .drag-area {
      border: 2px dashed #707070;
      height: auto;
      width: 100%;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f4d5d1;
      padding: 0 10px 10px 10px;

      .icon {
        font-size: 30px;
      }

      header {
        font-size: 14px;
        font-weight: 500;
        color: $light;
        text-align: center;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: $light;
        // margin: 5px 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .btn-primary {
        font-size: 12px;
      }
    }
  }
}

.rightbar {
  box-shadow: $box-shadow;
  min-height: calc(100vh - 80px);
  .notes {
    height: calc(100vh - 120px);
    overflow: scroll;
    padding: 20px;

    .single-note {
      background-color: #ffeeec;
      margin-top: 10px;
      padding: 10px;

      h5 {
        font-size: 14px;
        font-weight: 600;
        color: $secondary;
      }

      p {
        font-size: 12px;
        font-weight: 300;
        color: $light;
      }
    }
  }
  .pinned {
    .roles {
      background-color: $white;
      padding: 5px;
      box-shadow: $box-shadow;
      form {
        display: flex;
        justify-content: space-around;
      }
      label {
        font-size: 12px;
        color: $light;
      }
    }
  }
}

// Main chat
.main-chat {
  z-index: 9;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  .top-header {
    height: 80px;
    padding: 10px;
    background-color: #b7d784;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .scroll-nav {
      .count {
        background-color: $primary;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-size: 12px;
        color: $white;
        display: grid;
        place-items: center;
      }
    }
    .user-img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 10px;
    }
    h6 {
      color: $secondary;
      font-size: 12px;
      font-weight: 600;
    }
    p {
      text-align: right;
      font-size: 10px;
    }
  }
  .roles {
    background-color: $white;
    padding: 5px;
    box-shadow: $box-shadow;
    form {
      display: flex;
      align-items: center;
      justify-content: center;
      .form-check:not(:last-child) {
        margin-right: 30px;
      }
    }
    label {
      font-size: 12px;
      color: $light;
    }
  }

  .main-conversation {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
  .main-conversation-sm {
    padding-top: 20px;
    height: calc(100vh - 330px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
  }
  .message-input {
    padding-left: 30px;
    padding-right: 10px;
    width: 98%;
    .form-control {
      background-color: #f1f1f1;
      color: $secondary;
    }
  }
  .message-input-lg {
    width: calc(100% - 50px);
    margin-left: 30px;
    padding-top: 10px;
    background-color: #f1f1f1;

    .form-control {
      background-color: #f1f1f1;
      color: $secondary;
      resize: none;
      border: none;
    }
    .char-count {
      background-color: #f1f1f1;
      text-align: right;
      font-size: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid $white;
    }
    .btn-collect {
      background-color: #f1f1f1;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      justify-content: space-between;
    }
  }
}

.more-icon {
  position: relative;
}
.more-wrapper {
  position: absolute;
  top: 18px;
  right: 0;
  background-color: #fff;
  padding: 7px;
  width: 70px;
  box-shadow: $box-shadow;
  border-radius: 4px;
  ul {
    margin: 0;
    li {
      font-size: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      span {
        margin-right: 5px;
      }
    }
  }
}
.clarify-list {
  list-style: disc;
  margin-bottom: 10px;
  color: $light;
  font-size: 14px;
  cursor: pointer;
}

.helper-list {
  list-style: disc;
  color: $light;
  font-size: 14px;
  padding: 10px 25px;
  cursor: pointer;
  &:hover {
    background-color: #fff8fb;
  }
}

.css-yk16xz-control {
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 0 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}
.css-tlfecz-indicatorContainer {
  padding: 0 !important;
}
