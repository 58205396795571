.main-content {
  width: calc(100% - 16rem);
  min-height: calc(100vh - 140px);
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .main-content {
    width: calc(100% - 6rem);
  }
}

.main-content-sm {
  width: calc(100% - 6rem);
  min-height: 80vh;
  margin-bottom: 30px;
  margin-top: 30px;
}

.dashboard-table {
  .bg-gray {
    background-color: #f6f6f6;
  }

  .btn-primary {
    font-size: 12px;
  }

  thead {
    th {
      font-weight: 400 !important;
    }
  }

  tbody {
    box-shadow: none;
  }

  .assign-btn {
    height: 15px;
    width: auto;
    cursor: pointer;
    position: relative;
    &::after {
      content: attr(data-tooltip);
      position: absolute;
      top: 5px;
      left: 5px;
      //   transform: translate(-50%, 0);
      background: green;
      text-align: center;
      border-radius: 8px;
      color: #000;
      font-size: 12px;
      padding: 6px 10px;
      width: auto;
      z-index: 10000000;
    }
  }

  .flag-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-bottom: 4px;
  }

  .os-name {
    color: $light;
    font-size: 12px;
    font-weight: 300;
  }

  .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    font-size: 14px;
  }

  .day {
    font-size: 12px;
    font-weight: 300;
  }

  .description {
    font-size: 14px;
  }

  .type {
    font-size: 14px;
  }
}

// Questions Section
.questions-section {
  .form-control {
    border: 1px solid #cecece;
    background-color: #fff3f2;
  }
}
.select-toggle {
  position: relative;
  .selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cecece;
    background-color: #fff3f2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
  }

  ::-webkit-scrollbar {
    width: 5px;
    margin: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #f3bdbd;
    border-radius: 2.5px;
    height: 20%;
  }

  .select-wrapper {
    // max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 45px;
    left: 0;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: $box-shadow;
    border-radius: 8px;
    z-index: 99;
    width: 300px;

    .btn {
      width: 100%;
    }

    .btn-sm {
      float: right;
      width: 30%;
    }

    .btn-outline-primary-100 {
      color: $light;
      font-weight: 300;
      font-size: 14px;
    }

    .btn-outline-primary-200 {
      color: $primary-200;
      font-weight: 300;
      font-size: 14px;
    }

    .form-group {
      label {
        font-size: 12px;
        margin-bottom: 0;
      }

      .form-control {
        border: 1px solid $primary-100;
      }
    }
  }

  .select-wrapper-right {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #fff;
    padding: 20px 10px;
    box-shadow: $box-shadow;
    border-radius: 8px;
    z-index: 99;
    width: 300px;

    .btn {
      width: 100%;
    }

    .btn-outline-primary-100 {
      color: $light;
      font-weight: 300;
      font-size: 14px;
    }

    .btn-outline-primary-200 {
      color: $primary-200;
      font-weight: 300;
      font-size: 14px;
    }

    .form-group {
      label {
        font-size: 12px;
        margin-bottom: 0;
      }

      .form-control {
        border: 1px solid $primary-100;
      }
    }
  }
  .select-wrapper-sm {
    width: 120px;
  }
}

.assign-moderator {
  .search-bar {
    padding-bottom: 10px;
    border-bottom: 1px solid $xlight;

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      background-color: $white;
      box-shadow: $box-shadow;
      border-radius: 32px;
      width: 100%;
    }
  }

  .assign-question {
    font-size: 12px;
    color: $secondary;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .user-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $xlight;

    .flag-img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-bottom: 4px;
    }

    .os-name {
      color: $light;
      font-size: 12px;
      font-weight: 300;
    }

    .user-img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
    }

    .day {
      font-size: 12px;
      font-weight: 300;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: $light;
    }
  }

  .btn-primary {
    font-size: 12px;
    float: right;
    margin-top: 10px;
  }
}

.full-width-content {
  min-height: calc(100vh - 110px);
  padding-top: 50px;
}
.welcome-message {
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 8px;
  border: 1px solid $primary;
  p {
    color: $light;
    margin-bottom: 10px;
  }
}
.question-type-list {
  background-color: $xlight;
  padding: 10px;
  border-radius: 4px;
  box-shadow: $box-shadow;
  margin-bottom: 10px;
}
.minh-100 {
  min-height: calc(100vh - 160px);
}
