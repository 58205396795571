.sidebar {
  width: 14rem;
  // min-height: calc(100vh - 150px);
  margin-top: 30px;
  &__wrapper {
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: $primary;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    min-height: calc(100vh - 170px);
    position: relative;

    .toggle {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $white;
      box-shadow: $box-shadow-dark;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: $primary;

      &:hover {
        background-color: $primary-200;
      }

      .toggle-menu {
        height: auto;
        width: 20px;
      }
    }

    .menu-items {
      display: flex;
      align-items: center;

      span {
        margin-left: 14px;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}

.sidebar-sm {
  width: 4rem !important;
  margin-top: 30px;
  .sidebar__wrapper {
    align-items: center;
  }

  .menu-items {
    display: flex;
    align-items: center;

    span {
      display: none;
    }
  }

  .chevron {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 4rem !important;

    .sidebar__wrapper {
      align-items: center;
    }

    .menu-items {
      display: flex;
      align-items: center;

      span {
        display: none;
      }
    }

    .chevron {
      display: none;
    }
  }
}

.navbar__link--active {
  opacity: 1 !important;

  .menu-items {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translate(0, -50%);
      height: 5px;
      width: 5px;
      border-radius: 50%;
      z-index: 100;
      background-color: #fff;
      box-shadow: $box-shadow-light;
    }
  }
}
