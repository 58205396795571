.login {
  &__wrapper {
    min-height: 100vh;
    background-color: $secondary;
    padding-top: 50px;
    padding-bottom: 50px;
    // text-align: center;
  }

  &__form {
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(255, 255, 255, 0.3);
    width: 380px;
    // min-height: 600px ;
    margin: auto;
    background-color: $white;
    padding: 30px;

    .logo {
      margin: 0 auto;
      height: 80px;
      margin-bottom: 30px;
    }

    .main-title {
      font-weight: 500;
      font-size: 24px;
      color: $primary;
      margin-bottom: 20px;
      text-align: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -10px;
        bottom: 10px;
        width: 40%;
        height: 1px;
        background-color: $primary;
      }

      &::after {
        content: "";
        position: absolute;
        right: -10px;
        bottom: 10px;
        width: 40%;
        height: 1px;
        background-color: $primary;
      }
    }

    .forgot-password {
      text-align: right;
      color: $primary;
      font-weight: 300;
    }

    .copyright {
      margin-top: 30px;
      padding: 10px;
      text-align: center;
      font-weight: 300;
      font-size: 14px;

      p {
        color: $secondary;
        position: relative;
      }

      a {
        color: $primary;
      }
    }

    p {
      font-size: 0.75rem;
    }
    .btn-primary {
      width: 100%;
      display: block;
    }
  }
  //   @media (max-width: 768px) {
  //     .login__form {
  //       width: 90%;
  //     }
  //   }
}

.back-link {
  display: flex;
  align-items: center;
  margin-top: 30px;
  color: $light;
  font-size: 14px;
  font-weight: 400;
}
