@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap");
$nunito: "Nunito", sans-serif;

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
.textCapitalize {
  text-transform: capitalize;
}
