.content-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.main-title {
  font-weight: 600;
  font-size: 24px;
  color: $primary;
  margin-bottom: 20px;
}

// Card
.card {
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  border: none;
  background-color: $white;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom: 1px solid $xlight;
}

// Form
label {
  color: $light;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $light;
  font-size: 12px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $light;
  font-size: 12px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $light;
  font-size: 12px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $light;
  font-size: 12px;
}

.form-control,
.custom-select {
  // border: none;
  background-color: $xlight;
  border: 1px solid $xlight;
  // border-bottom: 1px solid $light;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: 1px solid $light;
    box-shadow: none;
  }
}

// Table
.table {
  thead {
    // box-shadow: $box-shadow;
    background-color: $xlight;
  }

  td,
  th {
    border: none !important;
    border-bottom: 1px solid #f6f6f6 !important;
    position: relative;
  }
}

// Alert
.alert-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}
.toast {
  border: none;
  border-left: 5px solid $success;
}

// Modal right

.modal-right {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal-body {
    padding: 15px 15px 80px;
  }
}

/*Right*/
.modal-right .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

// Modal Left

.modal-left {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 25%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    left: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal-body {
    padding: 15px 15px 80px;
  }
}

/*Right*/
.modal-left .modal-dialog {
  left: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border: none;
  border-radius: 8px;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: $xlight;
  font-size: 18px;
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .modal-title {
    font-size: 18px;
    font-weight: 600;
  }
}

// Modal bottom

.modal-bottom {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 40%;
    height: auto;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    bottom: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .modal-body {
    padding: 15px 15px;
  }
}

/*Right*/
.modal-bottom .modal-dialog {
  right: 30px;
  bottom: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

// Loader
.loader-lg {
  width: 5rem;
  height: 5rem;
}

// Tab
.tab-wrapper {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.nav-tabs {
  // display: inline-flex;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .nav-link {
    border: none;
    color: $light;
    &.active {
      color: $primary;
      border-bottom: 2px solid $primary !important;
      background-color: transparent;
    }
  }
}
.tab-body {
  padding: 1rem;
}

// Dashboard
.dashboard-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .card {
    width: 16%;
    margin-bottom: 20px;
    .card-body {
      padding: 0.75rem;
    }
    h2 {
      color: $secondary;
      font-weight: 700;
      font-size: 22px;
      margin-top: 8px;
      margin-bottom: 5px;
    }
    h5 {
      color: $light;
      font-size: 14px;
      font-weight: 300;
    }
    h6 {
      font-size: 14px;
      font-weight: 300;
    }
  }
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }
}

// Pagination
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  p {
    color: $light;
    font-size: 14px;
    font-weight: 300;
  }
  .pagination-input_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    color: #212529;
    .pagination-input {
      text-align: center;
      width: 5rem;
      margin: 0 0.5rem;
    }
    button {
      padding: 0 0.8rem;
      color: white;
      border-color: #bc2a20;
      background: #bc291f;
      border-style: solid;
      border-radius: 20px;
      transition: transform 200ms;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
}
@media (max-width: 768px) {
  .pagination-wrapper {
    flex-direction: column;
  }
}
.pagination {
  .page-item {
    &:not(:last-child) {
      margin-right: 5px;
      border-radius: 4px;
    }
  }
  .page-link {
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: $secondary;
  }
  @media (max-width: 768px) {
    .page-link {
      padding: 0.2rem 0.6rem;
    }
  }
  .active {
    .page-link {
      background-color: $white !important;
      border-color: $primary;
      color: #000;
    }
  }
}

.custom-tooltip {
  position: relative;
}
.custom-tooltip:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translate(-50%, 0);
  background: green;
  text-align: center;
  border-radius: 8px;
  color: #000;
  font-size: 12px;
  padding: 6px 10px;
  width: auto;
  z-index: 10000000;
}

.pointer {
  cursor: pointer;
}
.DraftEditor-editorContainer {
  min-height: 300px;
  background-color: $xlight;
}
.question-bg {
  background-color: #b7d784;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.confirm-bg {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
  opacity: 0.55;
  overflow: hidden; /* disable scrolling*/
  z-index: 2; /* higher than all other items, but lower than 
    the confirm box*/
}
.main-wrapper {
  position: relative;
}

// Loader
.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.table-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.table-minheight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
}

.zodiac-select-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.noImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff8fb;
}

.user-selection-wrapper {
  background-color: $primary-100;
  padding: 10px 10px 0 10px;
}
.user-wrapper {
  background-color: $white;
  padding: 5px 10px;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  svg {
    margin-left: 10px;
  }
}
.astro-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}
.scroll-wrapper {
  height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll-wrapper::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

.scroll-wrapper::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-100;
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: 1px solid $primary-100;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
  height: 30px;
}

//for chat mention components

.mentions__input {
  position: relative;
  background-color: #f1f1f1;
  border: none !important;
  padding: 0px 10px;
  outline: none !important;
  color: #707070;
  font-size: 14px !important;
  word-break: break-all !important;
}

.pointer {
  cursor: pointer;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 2px 10px;
  &:focus {
    outline: none;
  }
}

.swal-footer {
  text-align: center !important;
  margin-top: 0;
}

.bg-white {
  background-color: #fff;
  box-shadow: $box-shadow;
}
.bg-gray {
  background-color: #d6d5d5 !important;
}

.image-card {
  width: 30vw;
  height: 30vw;
  object-fit: cover;
}

#lightbox-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 39, 39, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}
.no-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.dublicate-switch {
  margin-top: 10px;
  margin-bottom: 10px;
  label {
    margin-bottom: 0;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primary;
    &::before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  input:focus {
    .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  }

  /* Rounded sliders */
  .round {
    border-radius: 34px;
    &::before {
      border-radius: 50%;
    }
  }
}

.spin {
	animation: spin 2s ease infinite;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
