* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $nunito;
  background-color: $xlight;
}

a {
  color: inherit;
  text-decoration: none !important;
}
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
