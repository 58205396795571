.profile-section {
  .profile-wrapper {
    .card {
      padding: 10px;
    }

    .profile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .profile-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      h5 {
        font-size: 14px;
        color: $secondary;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        color: $light;
      }

      .btn-primary {
        font-size: 12px;
      }
    }
    .level-img {
      height: 30px;
      width: auto;
      margin-right: 10px;
    }

    .profile-info {
      background-color: $xlight;
      padding: 10px;
      margin-top: 10px;
      border-radius: 8px;

      .info-heading {
        color: #7a57ff;
        font-size: 14px;
      }

      .info-edit {
        color: $secondary;
        font-size: 12px;
      }

      h5 {
        color: $secondary;
        font-size: 14px;
      }

      p {
        color: $light;
        font-size: 14px;
      }

      .flag {
        height: 20px;
        width: auto;
        margin-left: 8px;
      }
    }

    .profile-queries {
      background-color: $xlight;
      padding: 10px;
      margin-top: 10px;
      border-radius: 8px;

      .info-heading {
        color: #1c70be;
        font-size: 14px;
      }
    }

    .queries {
      // padding-left: 20px;
      flex: 1;
      h5 {
        color: $secondary;
        font-size: 14px;
        font-weight: 600;
      }

      p {
        color: $light;
        font-size: 14px;
        // margin-top: 5px;
      }

      .flag-img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        // margin-bottom: 4px;
      }

      .accurate {
        background-color: #92d4e2;
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .profile-image {
      background-color: $xlight;
      padding: 10px;
      margin-top: 10px;
      border-radius: 8px;

      .info-heading {
        color: #7a57ff;
        font-size: 14px;
      }
      .image-wrapper {
        position: relative;
      }

      .kundali {
        background-color: $white;
        box-shadow: $box-shadow;
        height: 120px;
        width: 100%;
        padding: 5px;
        border-radius: 8px;
        object-fit: cover;
        &:hover ~ .deleteIcon {
          display: grid;
        }
      }
    }

    .profile-review {
      background-color: $xlight;
      padding: 10px;
      margin-top: 10px;
      border-radius: 8px;

      .info-heading {
        color: #ae7b01;
        font-size: 14px;
      }

      p {
        font-size: 12px;
        color: $light;
      }

      h6 {
        font-size: 12px;
        color: $secondary;
      }

      .progress {
        margin-top: 5px;
        height: 0.5rem;
      }
    }
  }
}

.drag-area {
  border: 2px dashed #707070;
  height: auto;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f4d5d1;
  padding: 10px 20px 20px 20px;

  .icon {
    font-size: 50px;
  }

  header {
    font-size: 14px;
    font-weight: 500;
    color: $light;
    text-align: center;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $light;
    margin: 5px 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .btn-primary {
    font-size: 12px;
  }
}

.drag-area.active {
  border: 2px solid #fff;
}

.message-wrapper {
  padding: 0;
  height: auto;
  // background-color: $white;
  // box-shadow: $box-shadow;
  // border-radius: 8px;
  .btn-primary {
    font-size: 12px;
    margin-right: 10px;
  }

  .message-header {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid $xlight;
  }
}
.messages {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
}
.messages-sm {
  height: 40vh;
}
.message-avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.customer {
  background-color: #b7d784;
}

.mod {
  background-color: #decdc3;
}
.moderator {
  background-color: #decdc3;
}

.astro {
  background-color: #f0dba4;
}
.astrologer {
  background-color: #f0dba4;
}
.system {
  background-color: #80bee7;
}

.single-message {
  padding: 10px;
  margin-left: 10px;
  position: relative;
  width: 92%;
  .pin {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 919;
  }
  h5 {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }

  h6 {
    color: $light;
    font-size: 12px;
    font-weight: 300;
    text-transform: capitalize;
  }

  p {
    font-size: 12px;
    color: $secondary;
    line-break: anywhere;
    white-space: pre-wrap;
  }

  .time {
    text-align: right;
    color: $light;
    font-size: 8px;
  }
}

.messages::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

.messages::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-100;
}

.messages::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: 1px solid $primary-100;
}

.bg-xlight {
  background-color: $xlight;
  padding: 15px 10px;
  display: flex;

  h5 {
    flex: 8;
    font-size: 14px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 2;
  }
}

.stat {
  p {
    color: $light;
    font-size: 12px;
  }

  .progress {
    margin-top: 5px;
    height: 0.5rem;
  }

  h6 {
    font-size: 12px;
    color: $secondary;
  }
}

.modal-leftbar {
  min-height: 80vh !important;
}
.fullWidth {
  .modal-dialog {
    max-width: 70% !important;
    .modal-title {
      font-size: 14px !important;
    }
  }
}
