.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: $box-shadow;

  &__left {
    display: flex;
    align-items: center;
    .logo {
      width: 12rem;
    }
    @media (max-width: 768px) {
      .logo {
        width: 3rem;
      }
    }
    .logo__img {
      width: 80%;
      height: auto;
      margin-right: 20px;
    }
  }
  ul {
    margin-bottom: 0 !important;
    .nav-item {
      color: $primary;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-dropdown {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .user-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      .user-name {
        margin-right: 20px;
        font-weight: 500;
        color: $primary;
        margin-bottom: 0;
        font-size: 14px;
      }
      .user-role {
        color: #333;
        font-size: 12px;
      }
      @media screen and (max-width: 768px) {
        .user-name,
        .user-role {
          display: none;
        }
      }
    }

    .notification {
      position: relative;

      .notification-wrapper {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 999;
        background-color: #fff;

        .toast {
          width: 350px;
          border-radius: 0;

          &:not(:last-child) {
            margin-bottom: 0;
          }

          .toast-body {
            border-bottom: 1px solid $xlight;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: $box-shadow;
}

.dropdown-item {
  display: flex;
  align-items: center;
  &:active {
    background-color: transparent;
  }
  svg {
    margin-right: 8px;
  }
}

// Search
.search-bar {
  position: relative;
  form {
    position: relative;
    z-index: 99;
    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      background-color: $white;
      box-shadow: $box-shadow;
      border-radius: 32px;
      width: 24rem;
    }
    @media (max-width: 768px) {
      .form-control {
        width: 10rem;
      }
    }
  }

  .search-wrapper {
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 25%;
    left: 0;
    z-index: 98;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: $box-shadow;
    border-radius: 16px;
  }
}
.mod-search-wrapper {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 25%;
  left: 0;
  z-index: 98;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: $box-shadow;
  border-radius: 16px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.search-items {
  margin-bottom: 10px;
  .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  .user-name {
    font-weight: 500;
    color: $secondary;
    margin-bottom: 0;
    font-size: 14px;
  }
  .flag-img {
    height: 14px;
    width: auto;
  }
  .os {
    font-size: 10px;
    color: $light;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: $secondary;
  }
  p {
    color: $light;
    font-size: 10px;
  }
}
