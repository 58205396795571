.center-xy{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.center-x{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.center-y{
    display: flex;
    flex-direction: row;
    align-items: center;
}
