// Colors
$primary: #bc2a20;
$primary-200: #ff493d;
$primary-100: #ffbfbe;
$secondary: #333333;
$light: #707070;
$xlight: #fff8fb;
$info: #385ada;
$danger: #ef2b24;
$success: #0c8a72;
$warning: yellow;
$white: #fff;
$dark: #333;

$box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$box-shadow-dark: 0 0 6px 0 rgba(0, 0, 0, 0.5);
$box-shadow-light: 0 0 6px 2px rgba(255, 255, 255, 0.5);
